@charset "utf-8";
@import "../ui/color";

.main-container {
    overflow-x: hidden;
    margin-top: 62px;
}

// 修饰图片
.img-decorating {
    position: absolute;
    z-index: -1;
}

.block {
    padding: 54px 0 0 0;
    
    .col-wrap {
        box-sizing: border-box;
    }
    .row{
        width: 100%;
        height: 343px;
        position: relative;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        .left-pic{
            left: 0px;
            position: absolute;
            width: 60%;
            height:360px;
        }
        .right-pic{
            position: absolute;
            text-align: center;
            width: 38%;
            left: 62%;
            height:360px;
        }
    }
}

.block-head {
    position: relative;
    padding-bottom: 53px;
    &::before {
        position: absolute;
        bottom: 0;
        left: 50%;
        content: ' ';
        width: 62px;
        height: 4px;
        // background-color: #5D77FF;
        transform: translate(-50%, 0);
    }
    h2 {
        font-size: 30px;
        color: #161411;
        line-height: 1;
        text-align: center;
        font-size:24px;
        font-family:PingFang SC;
        font-weight:500;
    }
    h3{
        font-size:14px;
        font-family:PingFang SC;
        font-weight:400;
        color:rgba(22,20,17,1);  
        text-align: center;
        padding-top: 25px;
    }
}

.banner {
    position: relative;
    min-height: 440px;
    max-height: 440px;
    background:#181F32;

    overflow: hidden;

    .pic {
        position: absolute;
        top: 0;
        left: 40%;
        width: 582px;   
        height: 440px;
    }

    .col-wrap {
        height: 100%;
    }

    .text {
        position: absolute;
        top: 52%;

        transform: translate(0, -50%);

        h2 {
            margin-bottom: 40px;
            font-size:30px;
            font-family:PingFang SC;
            font-weight:500;
            color:#FFFFFF;
        }

        h3 {
            margin-bottom: 60px;
            font-size:14px;
            font-family:PingFang SC;
            font-weight:400;
            color:#FFFFFF;
            line-height:28px;
        }

        .tag {
            margin-bottom: 50px;
            width: 40px;
            height: 2px;
            background:rgba(1,47,171,1);
        }

        a {
            text-align: left;
            font-size:14px;
            font-family:PingFang SC;
            font-weight:400;
            color: rgba(255,255,255,1);
            background:rgba(1,47,171,1);
            padding: 5px 21px 5px;
        }
    }
}
.block-box-1{
    height: 510px;
    background:white;
    
    .block-body{
        width: 70%;
        padding-left: 15%;
        padding-top: 71px;
    }
}
.block-box-2{
    height: 1000px;
    background: #F3F5FA;

    .block-body{
        .body-row{
            display: inline-block;
            padding-top: 17px;
            .body-left{
                float: left;
                width: 45%
            }
            .body-right{
                float: right;
                width: 45%
            }
            img{
                padding-top: 40px
            }
        }
        .title{
            font-size:14px;
            font-family:PingFang SC;
            font-weight:500;
            color:rgba(22,20,17,1);
        }
        .text{
            font-size:12px;
            font-family:PingFang SC;
            font-weight:400;
            color:rgba(22,20,17,1);
            line-height:24px;
            padding-top: 27px;
        }
        hr{
            width:2px;
            height:12px;
            background:rgba(1,47,171,1);
            border: none;
            display: inline-flex;
            margin-right: 9px;
        }
    }
}
.block-box-3{
    height: 635px;
    background:white;

    .block-body{
        text-align: center;
    }
}
.block-box-4{
    min-height: 1070px;
    background: #F3F5FA;

    .block-body{
        .body-row{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .row-item{
                display: inline-flex;
                height: 220px;
                background: #1D2A4E;
                color: #fff;
                margin-top: 30px
            }
            .title{
                width: 220px;
                font-size:22px;
                font-family:PingFang SC;
                font-weight:500;
                color:rgba(255,255,255,1); 
                padding-top: 30px;
                padding-left: 18px;
            }
            .text{
                font-size:12px;
                font-family:PingFang SC;
                font-weight:400;
                color:rgba(255,255,255,1);
                line-height:24px; 
                padding-top: 25px;
                padding-left: 18px;
                padding-right: 20px;
            }
            .number{
                width: 30px;
                height: 24px;
                background: #0849F9;
                color: white;
                display: inline-block;
                font-size:18px;
                font-family:PingFang SC;
                font-weight:600;
                text-align: center;
                margin-right: 6px;
            }
        }
    }
}
.block-box-5{
    min-height: 700px;
    background:white;
}
.block-box-6{
    min-height: 672px;
    background: #F3F5FA;

    .block-body{
        margin-top: 24px;
    }
}
.block-box-7{
    background: white;
    height: 630px;

    .block-body{
        .body-item{
            float: left;
            position: relative;
            min-height: 276px;
            .item{
                margin-bottom: 21px;
                font-size: 16px;
                font-family:PingFang SC;
                font-weight:400;
                color:rgba(22,20,17,1);
                img{
                    vertical-align: middle;
                    margin-right: 25px;
                }
            }
            hr{
                width:157px;
                height:2px;
                background:rgba(229,229,229,1);  
                border: none;
                margin-left: 7px;
                margin-right: 7px;
            }
            .bottom{
                position: absolute;
                bottom: 0
            }
            .tag{
                margin-bottom: 60px
            }
            .text{
                text-align: center;
                font-size:12px;
                font-family:PingFang SC;
                font-weight:400;
                color:rgba(22,20,17,1);
            }
            .center-img{
                margin-left: 110px
            }
        }
    }
}
.block-box-8{
    background: #F3F5FA;
    height: 672px;
    
    .block-body{
        color: white;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .body-item{
            position: relative;
            height: 440px;
            background-color: #fff;
            h2{
                font-size:20px;
                font-family:PingFang SC;
                font-weight:500;
                color:rgba(22,20,17,1);
                padding-top: 36px;
                padding-left: 26px;
            }
            h3{
                font-size:14px;
                font-family:PingFang SC;
                font-weight:400;
                color:rgba(22,20,17,1);
                line-height:30px;
                padding-top: 33px;
                padding-left: 26px;
            }
        }
    } 
}